import { PostMessageType } from "../const";
import { IHeightChangedMessage } from "../interfaces/IPostMessage";
import { heightVh } from "../vhresize";

(window as any)._previousHeight = 0;
export const getMyName = (): string => {
  return window.name;
};

export function GetQueryStringByName(name: string): string {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regexS = "[\\?&]" + name + "=([^&#]*)";
  let regex = new RegExp(regexS);
  let results = regex.exec(window.location.search);
  if (results == null) return "false";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getHeight() {
  const bodyScrollHeight = document.body.scrollHeight;
  const htmlHeight = document.getElementsByTagName("html")[0]?.getBoundingClientRect().height || 0;
  return Math.max(bodyScrollHeight, htmlHeight);
}

function debounce<Params extends any[]>(func: (...args: Params) => any, timeout: number): (...args: Params) => void {
  let timer: number | undefined;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function initDocObserver(_isDelegateHeight = false, _isRequiredCallback = false) {
  let target = document.body;

  let observer = new MutationObserver(function (mutations) {
    let previousHeight = (window as any)._previousHeight;

    if (previousHeight !== target.clientHeight) {
      target.style.overflowY = "hidden";
      debounce(function () {
        let _currentHeight = getHeight();
        let heightChanged: IHeightChangedMessage = {
          messageType: PostMessageType.HEIGHT_CHANGED,
          payload: {
            height: _currentHeight,
            iFrameName: getMyName(),
          },
        };
        window.parent.postMessage(heightChanged, "*");

        // update height based on vh
        heightVh("[app36-height]");
        window.top!.document.body.style.overflowY = "auto";

        const childFrames = window.top!.document.getElementsByClassName("app1-dashboard-app-frame");
        for (let index = 0; index < childFrames.length; index++) {
          const childFrame = childFrames[index] as HTMLIFrameElement;
          if (childFrame.contentDocument) {
            childFrame.contentDocument.body.style.overflowY = "auto";
            let modalDom = childFrame.contentDocument.querySelector("#modal iframe") as HTMLIFrameElement | null;
            if (modalDom?.contentDocument?.body) {
              modalDom.contentDocument.body.style.overflowY = "auto";
            }
          }
        }
      }, 500)();
      (window as any)._previousHeight = target.clientHeight;
    }
  });

  // configuration of the observer:
  let config = { attributes: true, childList: true, characterData: true, subtree: true };

  // pass in the target node, as well as the observer options
  observer.observe(target, config);
}
