const getCurrentWindowStartPosition = () => {
  let sum = 0;
  let currentWindow: Window = window;

  const calculateSum = () => {
    try {
      //  if the frame has the answer buttons then remove the height of answer buttons
      const flowMenu = currentWindow.document.querySelector('[data-flowmenu="flowmenu"]');
      if (flowMenu) {
        const flowMenuPosition = flowMenu.getAttribute("data-position");
        if (flowMenuPosition === 'BOTTOM') {
          sum += flowMenu.getBoundingClientRect().height;
        }
      }
    } catch {}

    try {
      const footerElement = currentWindow.document.querySelector('[data-app-component="content-end"]');
      if (footerElement) {
        sum += footerElement.getBoundingClientRect().height;
      }
    } catch {}

    try {
      const allFrames = currentWindow.parent.document.getElementsByTagName("iframe");
      let thisFrame = null;
      // loop through all frames of that window
      for (let i = 0; i < allFrames.length; i++) {
        // find the current window from all windows
        if (allFrames.item(i)?.contentWindow === currentWindow) {
          thisFrame = allFrames.item(i);
          break;
        }
      }
      sum -= currentWindow.parent.document.body.scrollTop;
      if (thisFrame) {
        sum += thisFrame.getBoundingClientRect().y;
      }
    } catch {}
  };

  while (true) {
    calculateSum();
    currentWindow = currentWindow.parent;
    if (currentWindow === window.top) {
      calculateSum();
      break;
    }
  }
  return sum;
};

const getAvailableHeight = (element: HTMLElement) => {
  if (element) {
    const windowStartPosition = getCurrentWindowStartPosition();
    const result = (window.top || window).innerHeight - windowStartPosition - element.getBoundingClientRect().y;
    return `${result}px`;
  } else {
    return "auto";
  }
};

export const heightVh = (dataElement: string) => {
  const elementsWithApp36Height = document.querySelectorAll(dataElement);

  const elementsArray: HTMLElement[] = Array.from(elementsWithApp36Height) as HTMLElement[];
  // Loop through the selected elements
  elementsArray.forEach((element: HTMLElement) => {
    const app36HeightValue = element.getAttribute("app36-height");
    if (app36HeightValue) {
      if (app36HeightValue === "max-available") {
        element.style.height = getAvailableHeight(element);
        element.style.overflowY = "auto";
      } else {
        const regex = /height-vh-(\+|-)?(\d+)/; // Regular expression to match the pattern

        const match = app36HeightValue.match(regex);

        if (match && match.length >= 3) {
          const sign = match[1] || ""; // Get the sign (if present) from the match
          const number = parseInt(sign + match[2]);          

          if (window.parent) {
            element.style.height = window.parent.innerHeight + number + "px";
            element.style.overflowY = "auto";
          }
        } else {
          console.log("Invalid string or pattern not found.");
        }
      }
    }
  });
};
