import { GetQueryStringByName, initDocObserver } from "./utils/helper";
import { heightVh } from "./vhresize";

let _isDelegateHeight = GetQueryStringByName("DelegateHeight") == "true" ? true : false;
let _isRequiredCallback = GetQueryStringByName("RequireCallback") == "true" ? true : false;

initDocObserver(_isDelegateHeight, _isRequiredCallback);

(() => {
  try {
    window.addEventListener("message", (e) => {
      if (e.data?.messageType === "FLOW_VIEWER_PANEL_RESIZED") {
        heightVh("[app36-height]");
      }
    });
  } catch {}
})();
